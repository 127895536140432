var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TopInfo"),
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "page-content" }, [
          _c("div", { staticClass: "content-wrapper" }, [_c("router-view")], 1)
        ])
      ]),
      _c("LeftMenu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }