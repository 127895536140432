<template>
	<div id="rootVM" v-cloak style="display: flex; flex-direction: row">
		<div id="top-info">
			<div id="title">{{ viewNm }}</div>
			<div id="user-info">
				<img id="user-pic" src="../../../public/images/renew-topmenu-basic-user-pic.png" />
				<div id="user-name">{{ loginUserId }}</div>
				<div>
					<ul class="nav navbar-nav navbar-right" style="height: 48px; display: flex; align-items: center; padding-bottom: 5px">
						<li class="dropdown dropdown-user" v-if="loginUserId != ''">
							<!-- <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true"> -->
							<img
								id="drop-down"
								src="../../../public/images/renew-topmenu-arrow-drop-down.png"
								class="dropdown-toggle"
								data-toggle="dropdown"
								aria-expanded="true"
							/>
							<!-- </a> -->
							<ul class="dropdown-menu dropdown-menu-right">
								<li>
									<a @click="logout">
										<i class="icon-switch2"></i>
										로그아웃
									</a>
									<a @click="changeSiteId">
										<i class="icon-sync"></i>
										현장변경
									</a>
								</li>
							</ul>
						</li>
						<li class="dropdown dropdown-user" v-else>
							<a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<i class="icon-user-tie"></i>
								<span>Anonymous</span>
								<i class="caret"></i>
							</a>
							<ul class="dropdown-menu dropdown-menu-right">
								<!-- <li class="divider"></li> -->
								<li>
									<a href="/login">
										<i class="icon-switch2"></i>
										로그인
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="modal" sites>
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">현장 선택</h5>
					</div>
					<div class="modal-body">
						<select class="form-control" v-model="selectSite" style="font-size: 16px">
							<option value>선택</option>
							<option v-for="(option, index) in selectOption" :value="index" :key="index">
								{{ option.siteNm }}
							</option>
						</select>
					</div>
					<div class="modal-footer">
						<button class="btn btn-primary pull-right" @click="selectBtn" selectBtn>확인</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';
import jwt from 'jsonwebtoken';

let axiosExtention;
axiosExtention;

export default {
	data: () => ({
		loginUserNm: '',
		loginUserId: '',
		viewNm: '',
		mainUrl: '/',
		selectSite: '', // 현재 선택한 현장의 key value
		selectOption: [], // 셀렉트 박스 값(로그인할 유저의 현장들)
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.getLoginUser();
	},
	mounted() {
		this.mainUrl = apiIndex.mainUrl;
		this.viewNm = sessionStorage.getItem('viewNm');
	},
	methods: {
		getLoginUser() {
			// jwt 토큰에서 로그인 유저 아이디 가져오기
			let accessToken = VueCookies.get('V2_ACCESS_TOKEN');
			let decodedAccessToken = jwt.decode(accessToken);
			this.loginUserId = decodedAccessToken.userId;
			this.loginUserNm = decodedAccessToken.userNm;
		},
		logout() {
			// session storage 비우기
			sessionStorage.clear();
			// vue-cookies 제거
			VueCookies.remove('V2_ACCESS_TOKEN');
			location.href = '/login';
		},
		// 현장 변경
		changeSiteId() {
			let loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
			if (!loginUserInfo || loginUserInfo.length == 0) {
				this.getLoginUser();

				loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
			}
			// selectbox에 값에 필요한 현재 계정의 현장 정보를 조회
			this.$axios
				.post(apiIndex.login.inqChangeSiteIds, { userId: this.loginUserId, userRoleCd: loginUserInfo.userRoleCd })
				.then(
					function (r) {
						// 입력한 아이디와 비밀번호가 일치하는 회원이면 response 받은 현장 정보를 저장
						if (r.data) {
							// 셀렉트 박스 내용을 현재 로그인할 유저의 현장들로 세팅
							this.selectOption = r.data;

							// 로그인 할 계정의 현장이 2개 이상이면 모달을 호출
							if (r.data.length > 1) {
								$('.modal[sites]').modal({
									backdrop: 'static',
									show: true,
									keyboard: true,
								});
							}
							// 현장이 1개면 바로 로그인 처리
							else {
								this.selectSite = 0;
								this.selectBtn();
							}
						}
						// 현장이 0개일때
						else {
							this.selectSite = 0;
							this.selectBtn();
						}
					}.bind(this),
				)
				.catch(error => {
					alert(error.response.data.message);
				});
		},
		// 현장 선택 버튼 이벤트
		selectBtn() {
			// 로그인 유저의 현장이 0개일때
			if (!this.selectOption.length) return alert('소속된 현장이 없습니다.\n관리자에게 문의해주시기 바랍니다.');

			// 선택된 현장 값 세팅 후 form으로 넘기기
			if (this.selectSite === '') return alert('현장을 선택해 주십시오');

			let selectedSiteId = '';

			// 현장을 선택하면 여러 번 요청을 방지하기 위해 셀렉트 박스와 버튼을 disabled 처리
			$('[selectBtn]')
				.prop('disabled', true)
				.prepend("<i class='fa fa-spin fa-spinner'></i> ")
				.closest('.modal')
				.find('select')
				.prop('disabled', true);

			// hidden 타입으로 삽입된 현장 정보를 clear(로그인 실패할 경우 계속 쌓이는 걸 방지)
			$('#loginForm').find('input[type=hidden]').remove();

			// 현재 선택된 현장의 정보를 hidden 타입으로 삽입해 전달
			$.each(this.selectOption[this.selectSite ? this.selectSite : 0], function (k, v) {
				$('<input type=hidden>').attr({ name: k, value: v }).appendTo('#loginForm');
				if (k == 'siteId') selectedSiteId = v;
			});

			// selectbox에서 선택한 siteId 값을 jwt에 저장하기 위해 controller 한번 더 호출
			this.$axios
				.post(apiIndex.login.setUserSiteId, { siteId: selectedSiteId })
				.then(function (r) {
					window.location.href = r.data.redirectUrl;
				})
				.catch(error => {
					alert(error.response.data.message);
				});
		},
	},
};
</script>
<style scoped>
#top-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 16vw;
	width: 84vw;
	height: 7vh;
	background: #ebeff2;
	color: #000000;
	padding-left: 2rem;
}

#title {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 2rem;
	line-height: 21px;
	margin-left: 2rem;
}
#user-info {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 6vw;
	margin-right: 2rem;
}
#user-pic {
	width: 20%;
	height: 30%;
	margin-right: 0.5rem;
}

#drop-down {
	width: 20%;
	height: 10%;
	margin-left: 0.5rem;
	margin-right: 1rem;
	margin-top: 0.5rem;
}
</style>
