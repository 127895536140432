var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "flex", "flex-direction": "row" },
      attrs: { id: "rootVM" }
    },
    [
      _c("div", { attrs: { id: "left-menu" } }, [
        _vm._m(0),
        _c("div", { staticClass: "row", attrs: { id: "user-info-wrapper" } }, [
          _vm._m(1),
          _c("div", { attrs: { id: "user-name" } }, [
            _vm._v(_vm._s(_vm.loginUserNm))
          ]),
          _c("div", { attrs: { id: "user-role" } }, [
            _vm._v(_vm._s(_vm.loginUserRole))
          ])
        ]),
        _c("div", { attrs: { id: "menu-wrapper" } }, [
          _vm.menuGubun == "02"
            ? _c("ul", { staticClass: "nav nav-pills nav-stacked" }, [
                _vm.hasAuthority(["/viewAccidentPrevent.do"])
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-menu-custom",
                          attrs: { "data-desc": "재해예방조치" },
                          on: {
                            click: function($event) {
                              return _vm.loadView("accidentPrevent", $event)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "menu-icons",
                            attrs: {
                              src: require("../../../public/images/renew-leftmenu-자세히보기.png")
                            }
                          }),
                          _c("span", [_vm._v("재해예방조치")])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewDangerOccur.do",
                  "/viewWarnWorkOccur.do"
                ])
                  ? _c("li", { staticClass: "dropdown-menu-custom" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "dropdown-content" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewDangerOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "위험발생이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "dangerOccur",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험발생이력")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewDangerOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "위험발생이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "dangerOccurVideo",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험발생이력-비디오")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "주의작업이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "warnWorkOccur",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("주의작업이력")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "주의작업이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "warnWorkOccurVideo",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("주의작업이력-비디오")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewTree.do",
                  "/viewSiteStatistic.do",
                  "/viewAccidentStatistic.do",
                  "/viewObjectDangerOccurStatistic.do"
                ])
                  ? _c("li", { staticClass: "dropdown-menu-custom" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "dropdown-content" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewTree.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "현장별사용자조회" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("tree", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장별 사용자 조회")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSiteStatistic.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "현장별통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "siteStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장별통계")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewAccidentStatistic.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "재해유형별통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "accidentStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("재해유형별통계")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatistic.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "대상물별위험통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("대상물별위험통계-Line")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatistic.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "대상물별위험통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticBar",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("대상물별위험통계-Bar")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority(["/viewExemplaryCase.do"])
                  ? _c("li", { staticClass: "dropdown-menu-custom" }, [
                      _c(
                        "a",
                        {
                          attrs: { "data-desc": "모범사례" },
                          on: {
                            click: function($event) {
                              return _vm.loadView("exemplaryCase", $event)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "menu-icons",
                            attrs: {
                              src: require("../../../public/images/renew-leftmenu-exemplaryCase.png")
                            }
                          }),
                          _c("span", [_vm._v("모범사례")])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewNotice.do",
                  "/viewCompany.do",
                  "/viewSite.do",
                  "/viewUser.do",
                  "/viewMenu.do",
                  "/viewRoleGroup.do"
                ])
                  ? _c("li", { staticClass: "dropdown-menu-custom" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "dropdown-content" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewNotice.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "공지사항" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("notice", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("공지사항")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCompany.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "업체관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("company", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("업체관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSite.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "현장관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("site", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewUser.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "사용자관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("user", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("사용자관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewRegisterUser.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "사용자가입승인" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "registerUser",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("사용자가입승인")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSettingPushAlarm.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "푸시알림관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "settingPushAlarm",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("푸시알림관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewMenu.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "메뉴코드관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("menu", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("메뉴코드관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewRoleGroup.do"]) &&
                          _vm.loginUserId == "sysadmin"
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "권한그룹관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("roleGroup", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("권한그룹관리")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewGrpCode.do",
                  "/viewCommCode.do",
                  "/viewObject.do",
                  "/viewTarget.do",
                  "/viewObjectTarget.do",
                  "/viewWarnWork.do",
                  "/viewCctv.do",
                  "/viewBasicAiConfig.do",
                  "/viewCctvAiConfig.do",
                  "/viewApiCallErrLog.do",
                  "/viewCctvDangerAreaConfig.do",
                  "/viewSpeaker.do",
                  "/viewSpeakerSoundsrc.do",
                  "/viewSpeakerCctv.do",
                  "/viewCctvBySafeManager.do",
                  "/viewSpeakerAiConfig.do"
                ])
                  ? _c("li", { staticClass: "dropdown-menu-custom" }, [
                      _vm._m(5),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-content",
                          staticStyle: { width: "320px" },
                          attrs: { id: "AI-data-management" }
                        },
                        [
                          _c("ul", [
                            _vm.hasAuthority(["/viewGrpCode.do"]) &&
                            _vm.loginUserId == "sysadmin"
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "그룹코드관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView("grpCode", $event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("그룹코드관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewCommCode.do"]) &&
                            _vm.loginUserId == "sysadmin"
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "공통코드관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "commCode",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("공통코드관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewObject.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "대상물관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView("object", $event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("대상물관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewTarget.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "조치대상관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView("target", $event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("조치대상관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewObjectTarget.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "대상물조치대상관리"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "objectTarget",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("대상물조치대상관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewWarnWork.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "위험대상물조합관리"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "warnWork",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("위험대상물조합관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewCctv.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "CCTV관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView("cctv", $event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("CCTV관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewCctvAiConfig.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "CCTV 대상물 설정"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "cctvAiConfig",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("CCTV 대상물 설정")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewBasicAiConfig.do"]) &&
                            _vm.loginUserId == "sysadmin"
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "위험 판단 기준 설정"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "basicAiConfig",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("위험 판단 기준 설정")
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("ul", [
                            _vm.hasAuthority(["/viewApiCallErrLog.do"]) &&
                            _vm.loginUserId == "sysadmin"
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "API호출오류로그조회"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "apiCallErrLog",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("API호출오류로그조회")
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewCctvDangerAreaConfig.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "위험지역 설정" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "cctvDangerAreaConfig",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("위험지역 설정")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewSpeaker.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "스피커관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView("speaker", $event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("스피커관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewSpeakerSoundsrc.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "스피커음원관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "speakerSoundsrc",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("스피커음원관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewSpeakerCctv.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "스피커-CCTV 관계 설정"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "speakerCctv",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("스피커-CCTV 관계 설정")
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewCctvBySafeManager.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { "data-desc": "CCTV명관리" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "cctvBySafeManager",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("CCTV명관리")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority(["/viewSpeakerAiConfig.do"])
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "스피커대상물 설정"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "speakerAiConfig",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("스피커대상물 설정")])]
                                  )
                                ])
                              : _vm._e(),
                            _vm.hasAuthority([
                              "/viewAiConfigDistigshSutbDgreCctv.do"
                            ]) && _vm.loginUserId == "sysadmin"
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-desc": "AI-threshold 설정"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadView(
                                            "aiConfigDistigshSutbDgreCctv",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("AI-threshold 설정")])]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _c("li", { staticClass: "dropdown-menu-custom" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "dropdown-content" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              "data-desc": "dashboard",
                              target: "_blank"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadView("dashboard", $event)
                              }
                            }
                          },
                          [_c("span", [_vm._v("현장 모니터링")])]
                        )
                      ]),
                      _vm.hasAuthority(["/viewDashboardAll.do"])
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  "data-desc": "dashboardAll",
                                  target: "_blank"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.loadView("dashboardAll", $event)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("전체 현장 모니터링")])]
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row", attrs: { id: "logo-wrapper" } }, [
      _c("img", {
        attrs: {
          id: "logo",
          src: require("../../../public/images/a-safe-logo.png")
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          id: "user-pic",
          src: require("../../../public/images/renew-leftmenu-basic-user-pic.png")
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu-icons",
        attrs: {
          src: require("../../../public/images/renew-leftmenu-위험주의발생이력.png")
        }
      }),
      _c("span", [_vm._v("위험/주의발생이력")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu-icons",
        attrs: {
          src: require("../../../public/images/renew-leftmenu-statistic.png")
        }
      }),
      _c("span", [_vm._v("조회/통계")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu-icons",
        attrs: {
          src: require("../../../public/images/renew-leftmenu-organizationManagement.png")
        }
      }),
      _c("span", [_vm._v("조직관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu-icons",
        attrs: {
          src: require("../../../public/images/renew-leftmenu-AIdataManagement.png")
        }
      }),
      _c("span", [_vm._v("AI/데이터관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu-icons",
        attrs: {
          src: require("../../../public/images/renew-leftmenu-monitoring.png")
        }
      }),
      _c("span", [_vm._v("모니터링")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }