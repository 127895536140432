<template>
	<div id="rootVM" v-cloak style="display: flex; flex-direction: row">
		<div id="left-menu">
			<div id="logo-wrapper" class="row">
				<img id="logo" src="../../../public/images/a-safe-logo.png" />
			</div>
			<div id="user-info-wrapper" class="row">
				<div>
					<img id="user-pic" src="../../../public/images/renew-leftmenu-basic-user-pic.png" />
				</div>
				<div id="user-name">{{ loginUserNm }}</div>
				<div id="user-role">{{ loginUserRole }}</div>
			</div>
			<div id="menu-wrapper">
				<ul v-if="menuGubun == '02'" class="nav nav-pills nav-stacked">
					<li v-if="hasAuthority(['/viewAccidentPrevent.do'])">
						<a @click="loadView('accidentPrevent', $event)" data-desc="재해예방조치" class="dropdown-menu-custom">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-자세히보기.png" />
							<span>재해예방조치</span>
						</a>
					</li>
					<li v-if="hasAuthority(['/viewDangerOccur.do', '/viewWarnWorkOccur.do'])" class="dropdown-menu-custom">
						<a href="#">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-위험주의발생이력.png" />
							<span>위험/주의발생이력</span>
						</a>
						<div class="dropdown-content">
							<ul>
								<li v-if="hasAuthority(['/viewDangerOccur.do'])">
									<a @click="loadView('dangerOccur', $event)" data-desc="위험발생이력">
										<span>위험발생이력</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewDangerOccur.do'])">
									<a @click="loadView('dangerOccurVideo', $event)" data-desc="위험발생이력">
										<span>위험발생이력-비디오</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
									<a @click="loadView('warnWorkOccur', $event)" data-desc="주의작업이력">
										<span>주의작업이력</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
									<a @click="loadView('warnWorkOccurVideo', $event)" data-desc="주의작업이력">
										<span>주의작업이력-비디오</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li
						v-if="hasAuthority(['/viewTree.do', '/viewSiteStatistic.do', '/viewAccidentStatistic.do', '/viewObjectDangerOccurStatistic.do'])"
						class="dropdown-menu-custom"
					>
						<a href="#">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-statistic.png" />
							<span>조회/통계</span>
						</a>
						<div class="dropdown-content">
							<ul>
								<li v-if="hasAuthority(['/viewTree.do'])">
									<a @click="loadView('tree', $event)" data-desc="현장별사용자조회">
										<span>현장별 사용자 조회</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSiteStatistic.do'])">
									<a @click="loadView('siteStatistic', $event)" data-desc="현장별통계">
										<span>현장별통계</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewAccidentStatistic.do'])">
									<a @click="loadView('accidentStatistic', $event)" data-desc="재해유형별통계">
										<span>재해유형별통계</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
									<a @click="loadView('objectDangerOccurStatistic', $event)" data-desc="대상물별위험통계">
										<span>대상물별위험통계-Line</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
									<a @click="loadView('objectDangerOccurStatisticBar', $event)" data-desc="대상물별위험통계">
										<span>대상물별위험통계-Bar</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li v-if="hasAuthority(['/viewExemplaryCase.do'])" class="dropdown-menu-custom">
						<a @click="loadView('exemplaryCase', $event)" data-desc="모범사례">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-exemplaryCase.png" />
							<span>모범사례</span>
						</a>
					</li>
					<li
						v-if="hasAuthority(['/viewNotice.do', '/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewMenu.do', '/viewRoleGroup.do'])"
						class="dropdown-menu-custom"
					>
						<a href="#">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-organizationManagement.png" />
							<span>조직관리</span>
						</a>
						<div class="dropdown-content">
							<ul>
								<li v-if="hasAuthority(['/viewNotice.do'])">
									<a @click="loadView('notice', $event)" data-desc="공지사항">
										<span>공지사항</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCompany.do'])">
									<a @click="loadView('company', $event)" data-desc="업체관리">
										<span>업체관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSite.do'])">
									<a @click="loadView('site', $event)" data-desc="현장관리">
										<span>현장관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewUser.do'])">
									<a @click="loadView('user', $event)" data-desc="사용자관리">
										<span>사용자관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewRegisterUser.do'])">
									<a @click="loadView('registerUser', $event)" data-desc="사용자가입승인">
										<span>사용자가입승인</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSettingPushAlarm.do'])">
									<a @click="loadView('settingPushAlarm', $event)" data-desc="푸시알림관리">
										<span>푸시알림관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewMenu.do'])">
									<a @click="loadView('menu', $event)" data-desc="메뉴코드관리">
										<span>메뉴코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewRoleGroup.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
										<span>권한그룹관리</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li
						v-if="
							hasAuthority([
								'/viewGrpCode.do',
								'/viewCommCode.do',
								'/viewObject.do',
								'/viewTarget.do',
								'/viewObjectTarget.do',
								'/viewWarnWork.do',
								'/viewCctv.do',
								'/viewBasicAiConfig.do',
								'/viewCctvAiConfig.do',
								'/viewApiCallErrLog.do',
								'/viewCctvDangerAreaConfig.do',
								'/viewSpeaker.do',
								'/viewSpeakerSoundsrc.do',
								'/viewSpeakerCctv.do',
								'/viewCctvBySafeManager.do',
								'/viewSpeakerAiConfig.do',
							])
						"
						class="dropdown-menu-custom"
					>
						<a href="#">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-AIdataManagement.png" />
							<span>AI/데이터관리</span>
						</a>
						<div id="AI-data-management" class="dropdown-content" style="width: 320px">
							<ul>
								<li v-if="hasAuthority(['/viewGrpCode.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('grpCode', $event)" data-desc="그룹코드관리">
										<span>그룹코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCommCode.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('commCode', $event)" data-desc="공통코드관리">
										<span>공통코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObject.do'])">
									<a @click="loadView('object', $event)" data-desc="대상물관리">
										<span>대상물관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewTarget.do'])">
									<a @click="loadView('target', $event)" data-desc="조치대상관리">
										<span>조치대상관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectTarget.do'])">
									<a @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
										<span>대상물조치대상관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWork.do'])">
									<a @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
										<span>위험대상물조합관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctv.do'])">
									<a @click="loadView('cctv', $event)" data-desc="CCTV관리">
										<span>CCTV관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
									<a @click="loadView('cctvAiConfig', $event)" data-desc="CCTV 대상물 설정">
										<span>CCTV 대상물 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewBasicAiConfig.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('basicAiConfig', $event)" data-desc="위험 판단 기준 설정">
										<span>위험 판단 기준 설정</span>
									</a>
								</li>
							</ul>
							<ul>
								<li v-if="hasAuthority(['/viewApiCallErrLog.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
										<span>API호출오류로그조회</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
									<a @click="loadView('cctvDangerAreaConfig', $event)" data-desc="위험지역 설정">
										<span>위험지역 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeaker.do'])">
									<a @click="loadView('speaker', $event)" data-desc="스피커관리">
										<span>스피커관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerSoundsrc.do'])">
									<a @click="loadView('speakerSoundsrc', $event)" data-desc="스피커음원관리">
										<span>스피커음원관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerCctv.do'])">
									<a @click="loadView('speakerCctv', $event)" data-desc="스피커-CCTV 관계 설정">
										<span>스피커-CCTV 관계 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvBySafeManager.do'])">
									<a @click="loadView('cctvBySafeManager', $event)" data-desc="CCTV명관리">
										<span>CCTV명관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerAiConfig.do'])">
									<a @click="loadView('speakerAiConfig', $event)" data-desc="스피커대상물 설정">
										<span>스피커대상물 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewAiConfigDistigshSutbDgreCctv.do']) && loginUserId == 'sysadmin'">
									<a @click="loadView('aiConfigDistigshSutbDgreCctv', $event)" data-desc="AI-threshold 설정">
										<span>AI-threshold 설정</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="dropdown-menu-custom">
						<a href="#">
							<img class="menu-icons" src="../../../public/images/renew-leftmenu-monitoring.png" />
							<span>모니터링</span>
						</a>
						<div class="dropdown-content">
							<ul>
								<li>
									<a @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
										<span>현장 모니터링</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewDashboardAll.do'])">
									<a @click="loadView('dashboardAll', $event)" data-desc="dashboardAll" target="_blank">
										<span>전체 현장 모니터링</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';
import jwt from 'jsonwebtoken';

let axiosExtention;
axiosExtention;

export default {
	data: () => ({
		loginUserNm: '',
		loginUserId: '',
		loginUserRole: '',
		loginUserMenuList: [],
		menuGubun: '',
		mainUrl: '/',
		selectSite: '', // 현재 선택한 현장의 key value
		selectOption: [], // 셀렉트 박스 값(로그인할 유저의 현장들)
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.getCommCodeList();
		this.getLoginUser();
	},
	mounted() {
		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.mainUrl = apiIndex.mainUrl;
		// AI/데이터관리에 메뉴가 10개 미만이면, 메뉴 width 를 줄이는 용도
		this.$nextTick(function () {
			var numOfDataManagementList = 0;
			var AIdataManageMent = document.getElementById('AI-data-management');
			AIdataManageMent.childNodes.forEach(function (node) {
				numOfDataManagementList += node.childElementCount;
			});
			if (numOfDataManagementList < 10) {
				AIdataManageMent.style.width = '160px';
			}
		});
	},
	methods: {
		getLoginUser() {
			// jwt 토큰에서 로그인 유저 아이디 가져오기
			let accessToken = VueCookies.get('V2_ACCESS_TOKEN');
			let decodedAccessToken = jwt.decode(accessToken);
			this.loginUserId = decodedAccessToken.userId;
			this.loginUserNm = decodedAccessToken.userNm;
			this.loginUserMenuList = JSON.parse(decodedAccessToken.userMenu);
			this.loginUserRole = JSON.parse(sessionStorage.getItem('commonCodeList')).filter(
				code => code.grpCd == 'user_role_cd' && code.value == decodedAccessToken.userRoleCd,
			)[0].text;
			sessionStorage.setItem('loginUserInfo', JSON.stringify(decodedAccessToken));
			// console.log(sessionStorage.getItem('loginUserInfo'));
		},
		async loadView(name, e) {
			// name: 연결 Url 혹은 name / e: click Event 정보(e는 필요하면 사용 - 사용 안하면 지워주세요)
			// ex) name: exemplaryCase(모범사례 - 목록) name에 따라
			// if / switch로 분기하여 정보 조회 url, 연결 화면 url 을 지정하여 사용
			// 또는 @click마다 function 호출 다르게 적용
			console.log('loadView', 'name', name, 'e', e, 'e.target', e.target);
			// var veiwNm = ''
			let url = '';
			if (name == 'accidentPrevent') {
				url = apiIndex.accidentPrevent.viewAccidentPrevent;
			} else if (name == 'warnWorkOccur') {
				url = apiIndex.warnWorkOccur.viewWarnWorkOccur;
			} else if (name == 'warnWorkOccurVideo') {
				url = apiIndex.warnWorkOccur.viewWarnWorkOccurVideo;
			} else if (name == 'dangerOccur') {
				url = apiIndex.dangerOccur.viewDangerOccur;
			} else if (name == 'dangerOccurVideo') {
				url = apiIndex.dangerOccur.viewDangerOccurVideo;
			} else if (name == 'siteStatistic') {
				url = apiIndex.siteStatistic.viewSiteStatistic;
			} else if (name == 'accidentStatistic') {
				url = apiIndex.accidentStatistic.viewAccidentStatistic;
			} else if (name == 'site') {
				url = apiIndex.site.viewSite;
			} else if (name == 'user') {
				url = apiIndex.user.viewUser;
			} else if (name == 'menu') {
				url = apiIndex.menu.viewMenu;
			} else if (name == 'roleGroup') {
				url = apiIndex.roleGroup.viewRoleGroup;
			} else if (name == 'commCode') {
				url = apiIndex.commCode.viewCommCode;
			} else if (name == 'object') {
				url = apiIndex.object.viewObject;
			} else if (name == 'objectTarget') {
				url = apiIndex.objectTarget.viewObjectTarget;
			} else if (name == 'warnWork') {
				url = apiIndex.warnWork.viewWarnWork;
			} else if (name == 'cctv') {
				url = apiIndex.cctv.viewCctv;
			} else if (name == 'basicAiConfig') {
				url = apiIndex.basicAiConfig.viewBasicAiConfig;
			} else if (name == 'cctvAiConfig') {
				url = apiIndex.cctvAiConfig.viewCctvAiConfig;
			} else if (name == 'apiCallErrLog') {
				url = apiIndex.apiCallErrLog.viewApiCallErrLog;
			} else if (name == 'cctvDangerAreaConfig') {
				url = apiIndex.cctvDangerAreaConfig.viewCctvDangerAreaConfig;
			} else if (name == 'objectDangerOccurStatistic') {
				url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatistic;
			} else if (name == 'objectDangerOccurStatisticBar') {
				url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticBar;
			} else if (name == 'tree') {
				url = apiIndex.tree.viewTree;
			} else if (name == 'dashboard') {
				url = apiIndex.dashboard.viewDashboard;
			} else if (name == 'dashboardAll') {
				url = apiIndex.dashboardAll.viewDashboard;
			} else if (name == 'settingPushAlarm') {
				url = apiIndex.settingPushAlarm.viewSettingPushAlarm;
			} else if (name == 'speaker') {
				url = apiIndex.speaker.viewSpeaker;
			} else if (name == 'speakerSoundsrc') {
				url = apiIndex.speakerSoundsrc.viewSpeakerSoundsrc;
			} else if (name == 'speakerCctv') {
				url = apiIndex.speakerCctv.viewSpeakerCctv;
			} else if (name == 'registerUser') {
				url = apiIndex.registerUser.viewRegisterUser;
			} else if (name == 'cctvBySafeManager') {
				url = apiIndex.cctvBySafeManager.viewCctvBySafeManager;
			} else if (name == 'speakerAiConfig') {
				url = apiIndex.speakerAiConfig.viewSpeakerAiConfig;
			} else if (name == 'aiConfigDistigshSutbDgreCctv') {
				url = apiIndex.aiConfigDistigshSutbDgreCctv.viewAiConfigDistigshSutbDgreCctv;
			} else if (name == 'company') {
				url = apiIndex.company.viewCompany;
			} else if (name == 'grpCode') {
				url = apiIndex.grpCode.viewGrpCode;
			} else if (name == 'target') {
				url = apiIndex.target.viewTarget;
			} else if (name == 'exemplaryCase') {
				url = apiIndex.exemplaryCase.viewExemplaryCase;
			} else if (name == 'notice') {
				apiIndex.notice.viewNotice;
			}

			// topmenu에서 page이동 시 commonCodeList가 존재하지 않으면 commonCodeList 로드
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

			if (!commonCodeList || commonCodeList.length == 0) {
				await this.getCommCodeList();
			}

			if (!loginUserInfo || loginUserInfo.length == 0) {
				this.getLoginUser();
			}

			await this.$axios
				.post(url, {})
				.then(
					function (r) {
						if (r.data) {
							let data = r.data;
							if (data.token) delete data.token;

							// session clear 진행 시 commonCodeList까지 없어지는 것 방지
							const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
							const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

							/**
							 * session storage 비우기 (session 데이터 계속 쌓이는것 방지)
							 * 뒤로가기 시 각 vue에서 main으로 이동되게 처리
							 * 뒤로가기 허용 시 sessionStorage.clear(); 삭제
							 *  */
							sessionStorage.clear();

							if (name == 'dashboard' || name == 'dashboardAll') {
								localStorage.clear();
								localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								localStorage.setItem(name + 'PageParam', JSON.stringify(data));
								localStorage.setItem('viewNm', e.target.innerText);
								window.open(data.viewUrl);
							} else {
								sessionStorage.setItem('commonCodeList', JSON.stringify(commonCodeList));
								sessionStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								sessionStorage.setItem(name + 'PageParam', JSON.stringify(data));
								sessionStorage.setItem('viewNm', e.target.innerText);
								console.log('viewUrl', data.viewUrl);
								location.href = data.viewUrl;
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		async getCommCodeList() {
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			if (!commonCodeList || commonCodeList.length == 0) {
				await this.$axios
					.post(apiIndex.commCode.inqCommCodeAllList, {})
					.then(
						function (r) {
							sessionStorage.setItem('commonCodeList', JSON.stringify(r.data));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		hasAuthority(urlList) {
			const auth = urlList.some(r => this.loginUserMenuList.includes(r));
			return auth;
		},
	},
};
</script>
<style scoped>
#left-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	width: 16vw;
	height: 100vh;
	left: 0px;
	top: 0px;
}

#logo-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 7vh;
	left: 0px;
	top: 0px;
	padding-top: 2vh;
}

#logo {
	height: 90%;
}

#user-info-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 16vw;
	margin-top: 5vh;
	margin-bottom: 7vh;
}

#user-pic {
	width: 30%;
	margin-bottom: 5px;
}

#user-name {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 1.5rem;
}

#user-role {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	color: #666666;
}

.menu-icons {
	width: 2rem;
	margin-right: 0.5rem;
}

.dropdown-menu-custom {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 19px;
	color: #333333;
	position: relative;
	display: flex;
	z-index: 0;
}

.dropdown-menu-custom > a {
	width: 100%;
	display: block;
}

.dropdown-content {
	/* display: none; */
	display: flex;
	visibility: hidden;
	position: absolute;
	background-color: #f5f5f5;
	min-width: 160px;
	z-index: 99;
	top: 0px;
	left: 160px;
}

.dropdown-content > ul {
	color: #333333;
	font-weight: 600;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	list-style-type: none;
	min-height: 40px;
}

.dropdown-content > ul > li {
	height: 35px;
	text-align: center;
}

.dropdown-content > ul > li > a > span {
	color: #333333;
}

.dropdown-content > ul:hover {
	background-color: #f5f5f5;
}

.dropdown-menu-custom:hover > .dropdown-content {
	visibility: visible;
	display: flex;
	justify-content: center;
}

#AI-data-management > ul {
	width: 50%;
}
</style>
