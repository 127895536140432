import { render, staticRenderFns } from "./Default.renew.vue?vue&type=template&id=533b1bf5&"
import script from "./Default.renew.vue?vue&type=script&lang=js&"
export * from "./Default.renew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/aikl_dev/Projects/v2/kcc/aikl_v2_front_kcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('533b1bf5')) {
      api.createRecord('533b1bf5', component.options)
    } else {
      api.reload('533b1bf5', component.options)
    }
    module.hot.accept("./Default.renew.vue?vue&type=template&id=533b1bf5&", function () {
      api.rerender('533b1bf5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/Default.renew.vue"
export default component.exports