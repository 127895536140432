<template>
	<div>
		<TopInfo />
		<!-- Page container -->
		<div class="page-container">
			<!-- Page content -->
			<div class="page-content">
				<!-- Main content -->
				<div class="content-wrapper">
					<router-view />
				</div>
			</div>
		</div>
		<LeftMenu />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import LeftMenu from './Leftmenu.renew';
import TopInfo from './Topinfo.renew';

export default {
	name: 'Main',
	computed: {
		...mapState(['sideBarOpen']),
	},
	components: {
		LeftMenu,
		TopInfo,
	},
};
</script>
